import React, { MouseEvent, ReactNode } from 'react';
import { DescriptionWrapper, Element, ImageWrapper } from './FeaturedHoneymoon.styles';
import { Text } from '@/components/typography';
import Button from '@/components/button/Button/Button';
import CarrouselFeaturedHoneymoon from '@/features/home/components/FeaturedHoneymoon/CarrouselFeaturedHoneymoon';

export interface FeaturedHoneymoonProps {
  data: [FeaturedHoneyMoonType];
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const FeaturedHoneymoon = ({ data, children, ...props }: FeaturedHoneymoonProps) => {
  return (
    <Element>
      <ImageWrapper>
        <CarrouselFeaturedHoneymoon data={data} />
      </ImageWrapper>
      <DescriptionWrapper>
        <Text weight="bold" size="lg" color="white">
          A Lua de Mel é um momento de conexão emocional do casal, uma celebração particular após a correria dos
          preparativos do casamento.
        </Text>
        <Text size="base" color="white">
          O nosso papel é tornar esse momento a dois a realização de um grande sonho. A partir de uma conversa
          descontraída, desenvolvemos a viagem perfeita para o casal, personalizando todo o roteiro para que esta seja
          uma viagem tranquila e inesquecível.
        </Text>
        <div>
          <Button appearance="outline" arrow href="/viagens/lua-de-mel">
            Veja mais opções de lua de mel
          </Button>
        </div>
      </DescriptionWrapper>
    </Element>
  );
};

export default FeaturedHoneymoon;
