import React, { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';
import ImageFeatured from '@/components/image/ImageFeatured/ImageFeatured';
import PaginateContainer from '@/components/carrousel/PaginateContainer/PaginateContainer';
import { paginationOption } from '@/lib/swiperConfig';
import { CarrouselWrapper } from './CarrouselFeaturedHoneymoon.styles';

export interface CarrouselFeaturedProps {
  data: [FeaturedHoneyMoonType];
  children?: ReactNode;
}

const CarrouselFeaturedHoneymoon = ({ data, children, ...props }: CarrouselFeaturedProps) => {
  return (
    <CarrouselWrapper>
      <Swiper spaceBetween={0} slidesPerView={1} pagination={paginationOption} modules={[A11y, Pagination]}>
        {data.map((featured, index) => (
          <SwiperSlide key={featured.id}>
            <ImageFeatured
              radius={false}
              title={featured.destino.title}
              description={featured.title}
              href={featured.permalink}
              image={featured.imageLarge as ImageObjType}
              minHeight={587}
              minHeightMobile={469}
              titleProps={{
                as: 'h3',
                weight: 'black',
                size: '3xl',
                textCase: 'capitalize',
              }}
            />
          </SwiperSlide>
        ))}
        <PaginateContainer color="white" className="paginate-container" />
      </Swiper>
    </CarrouselWrapper>
  );
};

export default CarrouselFeaturedHoneymoon;
