import styled, { css } from 'styled-components';

export const CarrouselWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      height: 469px;
      overflow: hidden;
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        height: 584px;
      }
      .swiper {
        // This will make the Swiper container NOT cause a Layout Shift
        &:not(.swiper-initialized) {
          .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            width: 100%;
            overflow: hidden;
            gap: ${theme.spacing.base};
          }

          @media screen and (min-width: ${theme.breakpoint.sm}) {
            .swiper-wrapper {
              gap: ${theme.spacing.md};
            }
          }
        }
      }
    `;
  }};
`;
