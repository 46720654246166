import styled, { css } from 'styled-components';
import { FeaturedHoneymoonProps } from './FeaturedHoneymoon';

export const Element = styled.div<Omit<FeaturedHoneymoonProps, 'data'>>`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      flex-direction: column;
      background: ${theme.color.primary_500};
      border-top-left-radius: ${theme.radius.sm};
      border-top-right-radius: ${theme.radius.sm};
      margin: 0 -${theme.spacing.base};
      overflow: hidden;

      .paginate-container {
        padding: ${theme.spacing.base} 0;
        position: relative;
        bottom: 2rem;
        margin-top: -2.7rem;
        z-index: 2;
      }

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        flex-direction: row;
        border-bottom-left-radius: ${theme.radius.sm};
        border-bottom-right-radius: ${theme.radius.sm};
        > div {
          width: 50%;
        }
      }
    `;
  }};
`;

export const ImageWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
    `;
  }};
`;

export const DescriptionWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      gap: ${theme.spacing.base};
      flex-direction: column;
      justify-content: center;
      padding: ${theme.spacing.base};

      > div {
        > button,
        > a {
          width: 100%;
        }
      }

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        gap: ${theme.spacing.md};
        padding: ${theme.spacing.md};
        > div {
          > button,
          > a {
            width: auto;
          }
        }
      }
    `;
  }};
`;
